import Conditional from "@/components/Conditional";
import { useExpiration } from "../core";

export const withExpiration = (Component, FallbackComponent = Noop) => props => {
    const { canDisplay } = useExpiration(props);

    return (
        <Conditional
            when={canDisplay}
            then={() => (
                <Component
                    {...props}
                />
            )}
            otherwise={() => (
                <FallbackComponent
                    {...props}
                />
            )}
        />
    );
};

function Noop() {
    return null;
}

export default withExpiration;
