import { emitCustomEvent, withDomainNamespace } from "@/utils/custom-event";

const withEventName = withDomainNamespace('DiscountVoucher');

export const applyDiscountEvent = ({ detail = null }) => emitCustomEvent({
    eventName: applyDiscountEvent.eventName,
    target: window,
    detail
});

applyDiscountEvent.eventName = withEventName('ApplyDiscount');
