import { memo } from "react";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from "@mui/material/Alert";
import { useDiscountBannerContext } from "./core";

const DiscountNotification = ({ children, slotProps, ...restProps }) => {
    const {
        isDiscountNotificationOpen,
        closeDiscountNotification
    } = useDiscountBannerContext();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            {...restProps}
            open={isDiscountNotificationOpen}
            onClose={closeDiscountNotification}
            TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
                direction: 'left',
                ...restProps.TransitionProps
            }}
        >
            <Alert
                icon={false}
                onClose={closeDiscountNotification}
                {...slotProps.widget}
                sx={{
                    p: 0,
                    bgcolor: 'transparent',
                    position: 'relative',
                    '& .MuiAlert-action': {
                        position: 'absolute',
                        top: 20,
                        right: 20,
                    },
                    ...slotProps.widget.sx
                }}
            >
                {children}
            </Alert>
        </Snackbar>
    );
};

DiscountNotification.defaultProps = {
    TransitionComponent: Slide,
    slotProps: {
        widget: {},
    },
};

export default memo(DiscountNotification);
