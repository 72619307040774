export function emitCustomEvent({
    eventName,
    detail,
    target,
    eventSettings = {},
}) {
    const event = new CustomEvent(eventName, {
        detail,
        ...eventSettings
    });

    target.dispatchEvent(event);
};


export const withDomainNamespace = domainNamespace =>
    eventName => [
        domainNamespace,
        eventName
    ]
        .filter(Boolean)
        .join('.');
