import { useRef } from 'react';
import Cookies from 'js-cookie';

export class Voucher {
    name = '';
    code = '';
};

export function useDiscountVoucher({
    vouchers = []
} = {}) {
    const vouchersRef = useRef(new Set(vouchers));

    const getVouchers = () => Array.from(vouchersRef.current);

    const getVoucher = () => getVouchers()[0];

    const persistVoucher = (voucher, settings) => {
        const { name, code } = voucher ?? getVoucher();

        Cookies.set(name, code, settings);

        return code;
    };

    const getVoucherCodeFromCookie = (voucher = new Voucher) => {
        const { name } = getVoucher();

        return Cookies.get(name) || voucher.code;
    };

    const getPercentageDiscount = (voucher = getVoucher()) => {
        const match = getVoucherCodeFromCookie(voucher)
            .match(/-?\d+(\.\d+)?/);

        return match
            ? Number.parseFloat(match[0])
            : 0;
    };

    return {
        getVouchers,
        getVoucher,
        persistVoucher,
        getVoucherCodeFromCookie,
        getPercentageDiscount
    };
};

export default useDiscountVoucher;
