import Cookies from "js-cookie";

export default function useExpiration({ expiration = {} }) {
    const canDisplay = () => !Cookies.get(expiration.token);

    const rememberExpiration = () => {
        if (expiration.token) {
            Cookies.set(
                expiration.token,
                true,
                expiration.settings
            );
        }
    };

    return {
        canDisplay,
        rememberExpiration
    };
};
