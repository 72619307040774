import { useState } from "react";

export default function useDiscountBanner() {
    const [isDiscountNotificationOpen, setDiscountNotificationOpen] = useState(false);

    const openDiscountNotification = () => setDiscountNotificationOpen(true);
    const closeDiscountNotification = () => setDiscountNotificationOpen(false);

    return {
        isDiscountNotificationOpen,
        openDiscountNotification,
        closeDiscountNotification
    };
};
